import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";


const Platform = () => {
    return (
        <Row className="bg-dark bg-leaf-right p-4 p-lg-5">
            <Col>
                <Row className="justify-content-center w-md-75 mx-md-auto text-center">
                    <Col xs={12}>
                        <div className="text-center mb-5">
                            <h1><b>Our Platform</b></h1>
                        </div>
                        <Row className="justify-content-between text-center mb-md-2">
                            <Col xs={12} md={6} className="mb-3">
                                <Card className="p-3 bg-grey bg-darkmode-green b-0 h-100">
                                    <h4 className="text-yellow"><b>Staking</b></h4>
                                    Our Non-Custodial Staking lets you earn greatly needed $THC tokens! The more you hold, the more you earn.
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <Card className="p-3 bg-grey bg-darkmode-green b-0 h-100">
                                    <h4 className="text-yellow"><b>Raffles</b></h4>
                                    Join raffles by using $THC tokens for tickets to win NFTS or other prizes. More tickets means better odds!
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-between text-center">
                            <Col xs={12} md={6} className="mb-3">
                                <Card className="p-3 bg-grey bg-darkmode-green b-0 h-100">
                                    <h4 className="text-yellow"><b>Raid 2 Earn</b></h4>
                                    Holders get Solana rewards for raiding. Check for raids daily and monitor R2E alerts in our discord!
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <Card className="p-3 bg-grey bg-darkmode-green b-0 h-100">
                                    <h4 className="text-yellow"><b>Bounties</b></h4>
                                    Non-holders can participate in raids to earn XP for the leaderboard and free raffle tickets per bounty completed!
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <Link to='https://stake.thc-labz.xyz' className="btn btn-primary btn-yellow mx-auto mt-3 mt-md-5 py-2 px-md-3 fs-4" target="_blank">
                                    <b>Enter THC Labz Platform</b>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Platform;