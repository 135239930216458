const growerzImg = require('../../assets/growerz-hub.png');

const WalletBtn = () => {
    return (<a className="btn-growerz text-white px-3 py-2 py-md-3 br-5" href='https://growerz.thc-labz.xyz' target="_blank" rel="noreferrer">
        <div className="d-none d-md-inline-block">
            <img src={growerzImg} alt="Growerz Hub" height={42} />
        </div>
        <div className="d-inline-block d-md-none text-thc fs-6">
            Growerz&nbsp;Hub
        </div>
    </a>);
}

export default WalletBtn;