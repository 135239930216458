import { Link } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { Envelope } from "react-bootstrap-icons";

const xLogo = require('../assets/x-logo-green.png');
//const fillerImg = require('../assets/thc-labz-filler.png');
const kronicImg = require('../assets/kronic-king-banner.webp');
const shadowImg = require('../assets/shadow386-banner.png');
const beyondImg = require('../assets/beyond-banner.jpg');
const haizeelImg = require('../assets/haizeel-banner.jpg');
const skovImg = require('../assets/skovgotti84thclabz-banner.jpg');
const keyImg = require('../assets/key143-banner.png');
const astarothImg = require('../assets/astarothtrader-banner.jpg');
const kujaImg = require('../assets/kuja-banner.png');
const elbeeImg = require('../assets/elbee-banner.jpg')
const steveImg = require('../assets/stevebruce-banner.png')

const Team = () => {
    const teammates = [
        {
            name: 'The Kronic King',
            img: kronicImg,
            title: 'THC Labz Founder',
            xHandle: 'The_Kronic_King',
            email: 'kronic@thc-labz.xyz'
        },
        {
            name: 'Shadow386',
            img: shadowImg,
            title: 'Lead Developer',
            xHandle: 'shadow386',
            email: 'shadow@thc-labz.xyz'
        },
        /*{
            name: 'Beyond the Grove',
            img: beyondImg,
            title: 'Designer',
            xHandle: 'beyondthegrove4',
            email: 'beyond@thc-labz.xyz'
        },*/
        {
            name: 'Haizeel',
            img: haizeelImg,
            title: 'Artist',
            xHandle: 'HaizeelH'
        },
        {
            name: 'AstarothTrader',
            img: astarothImg,
            title: 'DeFi Team Lead',
            xHandle: 'AstarothTrader',
            email: 'astarothtrader@thc-labz.xyz'
        },
        {
            name: 'skovgotti84',
            img: skovImg,
            title: 'Discord Moderator',
            xHandle: 'skovgotti84'
        },
        {
            name: 'key143',
            img: keyImg,
            title: 'Discord Moderator',
            xHandle: 'KeysorNichole'
        },
        {
            name: "eLBee",
            img: elbeeImg,
            title: 'Discord Moderator',
            xHandle: 'hey_lbee'
        }
    ];

    return (
        <Row className="bg-dark bg-leaf p-4 p-lg-5">
            <Col>
                <Row className="justify-content-center w-md-75 mx-md-auto team-section">
                    <div className="text-center mb-3">
                        <h1><b>Meet the Team</b></h1>
                    </div>
                    {teammates.map(teammate => {
                        return (<Col xs={12} md={6} lg={3}>
                            <Card className="w-100">
                                <Card.Img src={teammate.img} width={"100%"} title={teammate.name} />
                                <Card.Body className="px-0">
                                    <h3 className="text-yellow">{teammate.name}</h3>
                                    <h5>{teammate.title}</h5>
                                    <p>
                                        <Link to={`https://x.com/${teammate.xHandle}`} target="_blank" title="X (Twitter)">
                                            <img src={xLogo} height={24} alt="X (Twitter)" />
                                        </Link>
                                        {teammate.email && (
                                            <Link to={`mailto:${teammate.email}`} target="_blank" title={teammate.email}>
                                                &nbsp;<Envelope size={28} />
                                            </Link>
                                        )}
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>);
                    })}
                </Row>
            </Col>
        </Row>
    )
}

export default Team;