import { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Container, Modal } from "react-bootstrap";
import './styles/app.min.css';

import AppRouter from './components/AppRouter';
import { Footer, Header } from './components/Layout';

const desktopVid = require('./assets/THC_Labz_header_vid_fade_desktop.mp4');
const mobileVid = require('./assets/THCLabz__Header_mobile_vertical.mp4');
const backgroundVid = require('./assets/Cinematic_Smoke_1080.mp4');

const App = () => {
  const [isLoading, setLoading] = useState(true);

  return (
    <Router>
      <Modal show={isLoading} fullscreen size="xl" data-bs-theme="dark" onHide={() => setLoading(false)}>
        <Modal.Header className="text-light bg-black b-0" closeButton></Modal.Header>
        <Modal.Body className="text-center bg-black d-none d-md-block">
          <video onEnded={() => setLoading(false)} style={{ height: '90%', width: '100%' }} autoPlay muted playsInline>
            <source src={desktopVid} type="video/mp4" />
          </video>
        </Modal.Body>
        <Modal.Body className="text-center bg-black d-block d-md-none">
          <video onEnded={() => setLoading(false)} style={{ height: '90%', width: '100%' }} autoPlay muted playsInline>
            <source src={mobileVid} type="video/mp4" />
          </video>
        </Modal.Body>
      </Modal>
      <div className="video-wrapper">
        <video playsInline autoPlay muted loop>
          <source src={backgroundVid} type="video/mp4" />
        </video>
      </div>
      <Header />
      <Container fluid className='g-0 main-content' data-bs-theme="dark">
        <div className="content text-light">
          <AppRouter />
        </div>
      </Container>
      <Footer />
    </Router>
  );
}

export default App;
