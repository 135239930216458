import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const token = require('../assets/THC.png');
const raydium = require('../assets/raydium.png');

const TokenInfo = () => {
    return (
        <Row className="bg-dark p-4 p-lg-5">
            <Col>
                <Row className="justify-content-center w-md-75 mx-md-auto text-center thc-stats">
                    <div className="mb-5">
                        <h1><b>$THC Token on Solana</b></h1>
                    </div>
                    <Col className="my-md-5 bg-darkmode-green">
                        <a href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=BmwJNuAAjFdKMfE9sWFb1YJJReJJGHLFsENPLkhjLbuT&fixed=out" target="_blank" rel="noreferrer">
                            <div className="m-3 block w-100 h-100">
                                <img src={raydium} height={72} className="mb-3" alt="Raydium" />
                                <br />
                                <span className="fs-5">
                                    Buy now on Raydium!
                                </span>
                            </div>
                        </a>
                    </Col>
                    <Col md={4} className="text-center d-none d-md-inline-block">
                        <img src={token} height={256} />
                    </Col>
                    <Col className="my-md-5 p-3 bg-darkmode-green">
                        <h1 className="text-yellow">42 M</h1>
                        <span className="fs-5">Total amount of $THC minted to be in circulation.</span>
                    </Col>
                    <Col className="text-center d-inline-block d-md-none py-3">
                        <img src={token} height={192} />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <Link to='https://famousfoxes.com/tokenmarket/BmwJNuAAjFdKMfE9sWFb1YJJReJJGHLFsENPLkhjLbuT' className="btn btn-primary btn-yellow mx-auto mt-3 mt-md-5 py-2 px-md-3 fs-4" target="_blank">
                            <b>Famous Foxes Token Market</b>
                        </Link>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default TokenInfo;