import { Link } from "react-router-dom";
import { Row, Col, Image } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';

const collectionImg = require('../assets/collection.gif');
const edenImg = require('../assets/magic-eden.png')

const Growerz = () => {
    return (
        <Row className="bg-leaf bg-dark-green p-4 p-lg-5">
            <Col>
                <Row className="justify-content-center w-md-75 mx-md-auto">
                    <Col className="text-center mb-3" xs={12} md={4}>
                        <Image src={collectionImg} alt="Collection Preview" rounded className="w-100" />
                    </Col>
                    <Col xs={12} md={8}>
                        <h1><b>The Growerz</b></h1>
                        <div className="p-3 bg-darkmode-green">
                            <p className="fs-5">
                                The Growerz is a collection of 2,420 stoner degeneratez that are just looking to grow, with legendary art from <a href="https://x.com/HaizeelH" title="X" rel="noreferrer">@HaizeelH</a>.
                            </p>
                        </div>
                        <Row>
                            <Col className="text-center" xs={12} md={6}>
                                <Link to='https://magiceden.io/marketplace/the_growerz' className="btn btn-primary btn-discord mx-auto mt-4 py-2 px-md-3 fs-4" target="_blank">
                                    <img src={edenImg} height={36} className="d-none d-md-inline-block" alt="Magic Eden" /> View Collection
                                </Link>
                            </Col>
                            <Col className="text-center" xs={12} md={6}>
                                <Link to='https://discord.gg/yHQCpf3fpp' className="btn btn-primary btn-discord mx-auto mt-4 py-2 px-md-3 fs-4" target="_blank">
                                    <Icon.Discord size={36} /> Visit Discord
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Growerz;