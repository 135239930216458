import { Col, Container, Row } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';

import WalletBtn from "./common/WalletBtn";

const logo = require('../assets/fancy-logo.png');
const icon = require('../assets/logo64.png');
const xLogo = require('../assets/x-logo-green.png');

export const Header = () => {
    return (<header>
        <Container fluid>
            <Row className="bg-black bg-dark-green social-links py-1 py-md-2 px-2 px-md-3 sticky-top bs-5">
                <Col className="pt-1 pb-1" xs={7} md={10}>
                    <a href="/" title="Home">
                        <div className="d-none d-md-inline-block">
                            <img src={icon} height={48} alt="THC Labz" />
                        </div>
                        <div className="d-inline-block d-md-none">
                            <img src={icon} height={36} alt="THC Labz" />
                        </div>
                    </a>
                    <a href="https://x.com/THC_Labz" title="X (formerly Twitter)" rel="noreferrer">
                        <img src={xLogo} height={20} alt="X" />
                    </a>
                    <a href="https://www.youtube.com/@THC_Labz" title="YouTube (@THC_Labz)" rel="noreferrer">
                        <Icon.Youtube size={24} />
                    </a>
                    <a href="https://open.spotify.com/show/79vZWOjMFdfNSaEFT0TMvM?si=1zK0kZPXRBiKuiyiephAtA" title="Spotify" rel="noreferrer">
                        <Icon.Spotify size={24} />
                    </a>
                    <a href="https://rss.com/podcasts/kronic/" title="Kronic Podcasts" rel="noreferrer">
                        <Icon.Rss size={24} />
                    </a>
                    <a href="https://linktr.ee/kronic_king" title="Linktree" rel="noreferrer">
                        <Icon.Link size={24} />
                    </a>
                </Col>
                <Col xs={5} md={2} className="text-center pt-2">
                    <WalletBtn />
                </Col>
            </Row>
            <Row className="justify-content-center text-center pt-5 pb-3 bg-black">
                <Col>
                    <img src={logo} alt="THC Labz" height="192" className="d-none d-md-block mx-auto" />
                    <img src={logo} alt="THC Labz" height="128" className="d-block d-md-none mx-auto" />
                </Col>
            </Row>
        </Container>
    </header>);
}

export const BackToTop = () => {
    return (
        <button title="Back to top" className="back2top fs-1 p-2 pt-md-1 text-center b-0" onClick={() => window.scrollTo(0, 0)}>
            ^
        </button>
    )
}

export const Footer = () => {
    return (<footer>
        <Container className="text-light py-5">
            <Row>
                <Col className="d-none d-md-block">
                    <button title="Back to top" className="bg-clear b-0 text-light" onClick={() => window.scrollTo(0, 0)}>
                        Back to top
                    </button>
                </Col>
                <Col className="text-center">
                    &copy; {new Date().getFullYear()} THC Labz
                </Col>
                <Col className="text-end social-links">
                    <a href="https://x.com/THC_Labz" title="X (formerly Twitter)" rel="noreferrer">
                        <img src={xLogo} height={20} alt="X" />
                    </a>
                    <a href="https://www.youtube.com/@THC_Labz" title="YouTube (@THC_Labz)" rel="noreferrer">
                        <Icon.Youtube size={24} />
                    </a>
                    <a href="https://open.spotify.com/show/79vZWOjMFdfNSaEFT0TMvM?si=1zK0kZPXRBiKuiyiephAtA" title="Spotify" rel="noreferrer">
                        <Icon.Spotify size={24} />
                    </a>
                    <a href="https://rss.com/podcasts/kronic/" title="Kronic Podcasts" rel="noreferrer">
                        <Icon.Rss size={24} />
                    </a>
                    <a href="https://linktr.ee/kronic_king" title="Linktree" rel="noreferrer">
                        <Icon.Link size={24} />
                    </a>
                </Col>
            </Row>
        </Container>
    </footer>);
}