import { Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const dtmImg = require('../assets/toymachine.png');
const url = 'https://www.solanatoymachine.com/machine/f2efb25f-e894-44d8-acf5-91477813d3b0';

const ToyMachine = () => {
    return (
        <Row className="bg-grey p-4 p-lg-5">
            <Col>
                <Row className="justify-content-center w-md-75 mx-md-auto dtm">
                    <Col xs={12} lg={6} className="mt-lg-5 pt-md-5">
                        <h1><b>Solana Toy Machine</b></h1>
                        <p className="fs-7">
                            <b>
                                Insert a coin, and win everytime!
                            </b>
                        </p>
                        <p className="fs-5 bg-darkmode-green p-3">
                            In partnership with OnCore, we have our very own Solana Toy Machine (STM). Our STM has a play price of <u><i>only</i></u> <b>0.025 Solana</b>! Insert a coin, and win a prize on every play!
                        </p>
                        <div className="text-center">
                            <Link to={url} className="btn btn-primary btn-yellow mx-auto mt-3 mt-md-5 py-2 px-md-3 fs-4" target="_blank">
                                <b>Launch Toy Machine</b>
                            </Link>
                        </div>
                    </Col>
                    <Col className="text-center mt-3 mt-lg-0">
                        <Image src={dtmImg} alt="Solana Toy Machine" rounded className="w-100" />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ToyMachine;