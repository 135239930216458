import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const zalezaiImg = require('../assets/zalezai-kronic-banner.webp');
const growerzImg = require('../assets/the-growerz-banner.webp');
const ruggedImg = require('../assets/rugged-kronic-banner.gif');
const edenImg = require('../assets/magic-eden.png')

const Collections = () => {
    const collections = [
        {
            name: 'The Growerz',
            img: growerzImg,
            url: 'https://magiceden.io/marketplace/the_growerz'
        },
        {
            name: 'ZalezAi X KronAIc',
            img: zalezaiImg,
            url: 'https://magiceden.io/marketplace/zalezai_x_kronaic'
        },
        {
            name: 'Rugged Kronic',
            img: ruggedImg,
            url: 'https://magiceden.io/marketplace/rugged-kronic'
        }
    ]

    return (
        <Row className="bg-dark-green p-4 p-lg-5">
            <Col>
                <Row className="justify-content-center w-md-75 mx-md-auto text-center eden-section">
                    <div className="text-center mb-3">
                        <h1><b>NFT Collections</b></h1>
                    </div>
                    {
                        collections.map(collection => (
                            <Col xs={12} md={4}>
                                <a href={collection.url} target="_blank" rel="noreferrer">
                                    <Card className="w-100 mb-3 mb-md-0">
                                        <Card.Img src={collection.img} width={"100%"} />
                                        <Card.Body>
                                            <h4 className="text-yellow">{collection.name}</h4>
                                        </Card.Body>
                                    </Card>
                                </a>
                            </Col>
                        ))
                    }
                </Row>
                <Row>
                    <Col className="text-center">
                        <Link to='https://magiceden.io/creators/thclabz' className="btn btn-primary btn-yellow mx-auto mt-3 mt-md-5 py-2 px-md-3 fs-4" target="_blank">
                            <b><img src={edenImg} height={36} className="d-none d-md-inline-block" alt="Magic Eden" /> Available on Magic Eden</b>
                        </Link>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Collections;